<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 col-lg-6 c g text-center">
      <b-link to="devices/add" class="btn btn-lg btn-relief-success">
        <i class="fa-solid fa-plus"></i>
        {{ e("add-device") }}
      </b-link>
    </div>
    <div class="col-12 g" v-if="devices.length">
      <br />
      <b-card :title="e('exists-devices')">
        <div class="col-12 table-responsive" style="min-height: 150px">
          <table
            class="table table-hover table-bordered table-striped custom-table"
          >
            <thead>
              <th>{{ e("title") }}</th>
              <th>{{ e("serial-number") }}</th>
              <th v-if="user.branches">{{ e("branche") }}</th>
              <th>{{ e("activate-status") }}</th>
              <th>{{ e("connection-status") }}</th>
              <th>{{ e("subscribe") }}</th>
              <th>{{ e("options") }}</th>
            </thead>
            <tbody>
              <tr v-for="device in devices" :key="device._id">
                <td>
                  <b-link
                    :to="'/devices/overview/' + device._id"
                    class="text-primary"
                    >{{ device.title }}</b-link
                  >
                </td>
                <td>
                  <b-link
                    :to="'/devices/overview/' + device._id"
                    class="text-primary"
                    >{{ device.serial_number }}</b-link
                  >
                </td>
                <td v-if="user.branches">{{ brancheName(device.branche) }}</td>
                <td>
                  <span v-if="device.status == '1'" class="badge bg-success">
                    {{ e("active") }}
                  </span>
                  <span v-if="device.status == '2'" class="badge bg-danger">
                    {{ e("stopped") }}
                  </span>
                  <span v-if="device.status == '0'" class="badge bg-warning">
                    {{ e("not-active") }}
                  </span>
                </td>
                <td>
                  <span
                    v-if="device.last_update == null"
                    class="badge bg-warning"
                  >
                    {{ e("not-connected-yet") }}
                  </span>
                  <span v-if="device.last_update">
                    <span
                      v-if="
                        timeAgo(device.last_update, device.time_zone).includes(
                          'now'
                        ) ||
                        timeAgo(device.last_update, device.time_zone).includes(
                          'second'
                        ) ||
                        timeAgo(device.last_update, device.time_zone).includes(
                          'ثوان'
                        )
                      "
                      class="badge bg-success"
                    >
                      {{ e("connected-now") }}
                    </span>
                    <span
                      v-if="
                        !timeAgo(device.last_update, device.time_zone).includes(
                          'now'
                        ) &&
                        !timeAgo(device.last_update, device.time_zone).includes(
                          'second'
                        ) &&
                        !timeAgo(device.last_update, device.time_zone).includes(
                          'ثوان'
                        )
                      "
                      class="badge bg-secondary"
                    >
                      {{ e("last-seen") }}:
                      {{ timeAgo(device.last_update, device.time_zone) }}
                    </span>
                  </span>
                </td>
                <td>
                  <span v-if="device.demo == '1'" class="badge bg-danger">
                    {{ e("demo") }}
                  </span>
                  {{ e("exdate") }}: {{ cdate(device.exdate) }}
                </td>
                <td>
                  <b-dropdown
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :text="e('options')"
                    variant="relief-secondary"
                  >
                    <b-dropdown-item @click="overview(device._id)">
                      <i class="fa fa-chart-pie text-success"></i>
                      {{ e("overview") }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="editDevice(device._id)">
                      <i class="fa fa-edit text-primary"></i>
                      {{ e("edit-device") }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="deleteDevice()"
                      ><i class="fa fa-trash text-danger"></i>
                      {{ e("delete-device") }}
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
    </div>
    <div class="col-12 g text-center" v-if="!devices.length">
      {{ e("no-devices") }}
    </div>
  </b-overlay>
</template>

<script>
var moment = require("moment-timezone");
import {
  BLink,
  BOverlay,
  BCard,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BLink,
    BOverlay,
    BCard,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      loading: true,
      user: JSON.parse(localStorage.getItem("user")),
      devices: [],
    };
  },
  methods: {
    e(d) {
      return e(d);
    },
    editDevice(id) {
      this.$router.push("devices/edit/" + id);
    },
    overview(id) {
      this.$router.push("devices/overview/" + id);
    },
    deleteDevice() {
      alert(e("delete-disabled"));
    },
    timeAgo(date, tz) {
      if (moment(date).tz(tz)) {
        return moment(date).lang(localStorage.getItem("lang")).tz(tz).fromNow();
      } else {
        return date;
      }
    },
    cdate: (date) => {
      return cdate(date);
    },
    brancheName(id) {
      var title = e("notfound");
      this.user.branches_list.forEach((b) => {
        if (b.id == id) {
          title = b.title;
        }
      });
      return title;
    },
  },
  created() {
    var _g = this;
    $.post(api + "/devices/index", {
      jwt: this.user.jwt,
    })
      .then(function (response) {
        response = JSON.parse(response);
        if (response.status == 100) {
          _g.devices = response.response;
        } else {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: e("error"),
              icon: "TimesIcon",
              variant: "danger",
            },
          });
        }
        _g.loading = false;
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: e("error"),
            icon: "TimesIcon",
            variant: "danger",
          },
        });
        _g.loading = false;
      });
  },
};
</script>